import { call, put, takeEvery } from "redux-saga/effects";

// types
import * as appTypes from "@/stores/types/app";
import * as types from "@/stores/types/meta-tags";

// services
import * as MetaTagsService from "@/stores/services/meta-tags";

function* getMetaTags() {
  const response = yield call(MetaTagsService.getMetaTags);

  if (response) {
    yield put({
      type: types.GET_META_TAGS_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: types.GET_META_TAGS_FAILURE,
      payload: response,
    });
  }
}

function* editMetaTag(data) {
  const response = yield call(MetaTagsService.editMetaTags, data);

  if (response.success === true) {
    yield put({
      type: appTypes.ALERT,
      payload: {
        success: response.success,
      },
    });
    yield put({
      type: types.EDIT_META_TAG_SUCCESS,
      payload: data.payload,
    });
  } else {
    yield put({
      type: appTypes.ALERT,
      payload: {
        success: response.success,
      },
    });
    yield put({
      type: types.EDIT_META_TAG_FAILURE,
      payload: response,
    });
  }
}

function* metaTagsSaga() {
  yield takeEvery(types.GET_META_TAGS, getMetaTags);
  yield takeEvery(types.EDIT_META_TAG, editMetaTag);
}

export default metaTagsSaga;
