/* eslint-disable no-case-declarations */
import { fromJS } from "immutable";

// types
import * as types from "../types/not-found-pages";

const initialState = fromJS({
  loading: false,
  redirect_404_status: false,
  redirect_404_path: "",
  redirect_404_logging: [],
  page: 0,
  count_not_found_link: 0,
  count_redirected_link: 0,
  count_not_found_session: 0,
  count_redirected_session: 0,
});

export const notFoundPagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_SETTINGS:
      return state.set(action.payload.field, action.payload.value);
    case types.GET_NOT_FOUND_PAGE:
    case types.GET_LIST_NOT_FOUND_PAGE:
      return state.set("loading", true);
    case types.EDIT_STATUS_NOT_FOUND_PAGE_SUCCESS:
      return state.set(
        "redirect_404_status",
        action.payload.redirect_404_status
      );
    case types.GET_NOT_FOUND_PAGE_SUCCESS:
    case types.EDIT_NOT_FOUND_PAGE_SUCCESS:
      return state
        .set("loading", false)
        .set("redirect_404_status", action.payload.redirect_404_status)
        .set("redirect_404_path", action.payload.redirect_404_path)
        .set("count_not_found_link", action.payload.count_not_found_link)
        .set("count_redirected_link", action.payload.count_redirected_link)
        .set("count_not_found_session", action.payload.count_not_found_session)
        .set(
          "count_redirected_session",
          action.payload.count_redirected_session
        );
    case types.GET_LIST_NOT_FOUND_PAGE_SUCCESS:
      return state
        .set("loading", false)
        .set("redirect_404_logging", action.payload.redirect_404_logging)
        .set("page", action.payload.page);
    case types.DELETE_NOT_FOUND_PAGE_SUCCESS:
      const redirectNotFoundLoggingNew = state
        .get("redirect_404_logging")
        .filter(page => page.pathname !== action.payload.pathname);
      return state.set("redirect_404_logging", redirectNotFoundLoggingNew);

    default:
      return state;
  }
};
