import { fromJS } from "immutable";

// types
import * as types from "../types/image-optimize";

const initialState = fromJS({
  loading: false,
  loading_history: false,
  optimization_status: "",
  image_alt_status: false,
  product_image_alt: "{{ product.title }} {{ shop.name }}",
  product_image_alt_overwrite: false,
  collection_image_alt: "{{ collection.title }} {{ shop.name }}",
  collection_image_alt_overwrite: false,
  blog_post_image_alt: "{{ article.title }} {{ shop.name }}",
  blog_post_image_alt_overwrite: false,
  image_filename_status: false,
  image_filename: "{{ product.title }} {{ shop.name }}",
  collection_image_filename: "{{ collection.title }} {{ shop.name }}",
  blog_post_image_filename: "{{ article.title }} {{ shop.name }}",
  history: [],
  page: 0,
  revert_status: "",
  product_report: null,
  collection_report: null,
  article_report: null,
  total_report: null,
  revert_loading: false,
  toggle_loading: false,
  toggle_revert_all_loading: false,
  revert_loading_item: "",
  total_report_running: null,
  filter_loading: false,
  totalCount: 0,
});

export const imageOptimizeReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_SETTINGS:
      return state.set(action.payload.field, action.payload.value);
    case types.GET_IMAGE_OPTIMIZE:
      return state.set("loading", true);
    case types.TOGGLE_IMAGE_OPTIMIZE:
      return state.set("toggle_loading", true);
    case "SET_OPTIMIZE_BY_SHOP_SUCCESS":
      return state.set("loading", false).set("toggle_loading", false);
    case "GET_DATA_IMAGE_BY_SHOP_SUCCESS":
      return state
        .set("count", action.payload.count)
        .set("list", action.payload.list)
        .set("page", action.payload.page)
        .set("totalCount", action.payload.totalCount);
    case "NEXT_RUN_OPTIMIZE_BY_SHOP":
    case "PAUSE_RUN_OPTIMIZE_BY_SHOP_FAILURE":
      return state.set("process", 1);
    case "NEXT_REVERT_OPTIMIZE_BY_SHOP":
    case "PAUSE_REVERT_OPTIMIZE_BY_SHOP_FAILURE":
      return state.set("process", 2);
    case "PAUSE_RUN_OPTIMIZE_BY_SHOP":
    case "NEXT_RUN_OPTIMIZE_BY_SHOP_FAILURE":
    case "PAUSE_REVERT_OPTIMIZE_BY_SHOP":
    case "NEXT_REVERT_OPTIMIZE_BY_SHOP_FAILURE":
      return state.set("process", 0);
    case "GET_OPTIMIZE_BY_SHOP_SUCCESS":
      return state
        .set("loading", false)
        .set("toggle_loading", false)
        .set("altConfigArticle", action.payload.altConfigArticle)
        .set("altConfigCollection", action.payload.altConfigCollection)
        .set("altConfigProduct", action.payload.altConfigProduct)
        .set("filenameConfigArticle", action.payload.filenameConfigArticle)
        .set(
          "filenameConfigCollection",
          action.payload.filenameConfigCollection
        )
        .set("filenameConfigProduct", action.payload.filenameConfigProduct)
        .set("overwriteAltArticle", action.payload.overwriteAltArticle)
        .set("overwriteAltCollection", action.payload.overwriteAltCollection)
        .set("overwriteAltProduct", action.payload.overwriteAltProduct)
        .set("process", action.payload.process)
        .set("processIdArticle", action.payload.processIdArticle)
        .set("processIdBlog", action.payload.processIdBlog)
        .set("processIdCollection", action.payload.processIdCollection)
        .set("processIdProduct", action.payload.processIdProduct)
        .set("statusAlt", action.payload.statusAlt)
        .set("statusFilename", action.payload.statusFilename);
    case types.GET_IMAGE_OPTIMIZE_SUCCESS:
    case types.EDIT_IMAGE_OPTIMIZE_SUCCESS:
    case types.TOGGLE_IMAGE_OPTIMIZE_SUCCESS:
      return state
        .set("loading", false)
        .set("toggle_loading", false)
        .set("optimization_status", action.payload.optimization_status)
        .set("image_alt_status", action.payload.image_alt_status)
        .set("product_image_alt", action.payload.product_image_alt)
        .set(
          "product_image_alt_overwrite",
          action.payload.product_image_alt_overwrite
        )
        .set("collection_image_alt", action.payload.collection_image_alt)
        .set(
          "collection_image_alt_overwrite",
          action.payload.collection_image_alt_overwrite
        )
        .set("blog_post_image_alt", action.payload.blog_post_image_alt)
        .set(
          "blog_post_image_alt_overwrite",
          action.payload.blog_post_image_alt_overwrite
        )
        .set("image_filename_status", action.payload.image_filename_status)
        .set("image_filename", action.payload.image_filename)
        .set(
          "collection_image_filename",
          action.payload.collection_image_filename
        )
        .set(
          "blog_post_image_filename",
          action.payload.blog_post_image_filename
        )
        .set("revert_status", action.payload.revert_status)
        .set("total_report_running", action.payload.report_running.total);
    case types.GET_IMAGE_OPTIMIZE_STATUS_SUCCESS:
      return state
        .set("optimization_status", action.payload.optimization_status)
        .set("total_report_running", action.payload.report_running.total);
    case types.GET_HISTORY_IMAGE_OPTIMIZE:
      return state.set("loading_history", true);
    case types.TOGGLE_REVERT_ALL_IMAGE_OPTIMIZE:
      return state.set("toggle_revert_all_loading", true);
    case types.REVERT_IMAGE_OPTIMIZE:
      return state.set(
        "revert_loading_item",
        action.payload.revert_loading_item
      );
    case types.GET_HISTORY_IMAGE_OPTIMIZE_SUCCESS:
    case types.REVERT_IMAGE_OPTIMIZE_SUCCESS:
      return state
        .set("loading_history", false)
        .set("revert_loading", false)
        .set("toggle_revert_all_loading", false)
        .set("product_report", action.payload.report.product)
        .set("collection_report", action.payload.report.collection)
        .set("article_report", action.payload.report.article)
        .set("total_report", action.payload.report.total)
        .set("history", action.payload.image_list_optimizer)
        .set("page", action.payload.page);

    default:
      return state;
  }
};
