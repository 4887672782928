/* eslint-disable import/prefer-default-export */
import { callApi } from "./service";

const dashboardApi = process.env.REACT_APP_DASHBOARD_API;
const googleApi = "https://www.googleapis.com/pagespeedonline/v5/runPagespeed";

export function* getPageSpeedInsights({ shop }) {
  const payload = {
    method: "GET",
    url: googleApi,
    params: {
      url: shop,
      key: process.env.REACT_APP_GOOGLE_API_KEY,
      category: "PERFORMANCE",
    },
  };
  return yield callApi(payload);
}

export function* getHomePageSpeedInsights({ shop }) {
  const payload = {
    method: "GET",
    url: googleApi,
    params: {
      url: shop,
      key: process.env.REACT_APP_GOOGLE_API_KEY,
      category: "PERFORMANCE",
    },
  };
  return yield callApi(payload);
}

export function* getCollectionPageSpeedInsights({ shop }) {
  const payload = {
    method: "GET",
    url: googleApi,
    params: {
      url: shop,
      key: process.env.REACT_APP_GOOGLE_API_KEY,
      category: "PERFORMANCE",
    },
  };
  return yield callApi(payload);
}

export function* getProductPageSpeedInsights({ shop }) {
  const payload = {
    method: "GET",
    url: googleApi,
    params: {
      url: shop,
      key: process.env.REACT_APP_GOOGLE_API_KEY,
      category: "PERFORMANCE",
    },
  };
  return yield callApi(payload);
}

export function* getRandomProductPageSpeedInsights() {
  const payload = {
    method: "GET",
    url: `${dashboardApi}/seo/random-product-speed-insights`,
    params: {},
  };

  return yield callApi(payload);
}
