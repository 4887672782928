export const GET_PAGE_SPEED_INSIGHTS =
  "page-speed-insights/GET_PAGE_SPEED_INSIGHTS";
export const GET_PAGE_SPEED_INSIGHTS_SUCCESS =
  "page-speed-insights/GET_PAGE_SPEED_INSIGHTS_SUCCESS";
export const GET_PAGE_SPEED_INSIGHTS_FAILURE =
  "page-speed-insights/GET_PAGE_SPEED_INSIGHTS_FAILURE";

export const GET_HOME_PAGE_SPEED_INSIGHTS =
  "page-speed-insights/GET_HOME_PAGE_SPEED_INSIGHTS";
export const GET_HOME_PAGE_SPEED_INSIGHTS_SUCCESS =
  "page-speed-insights/GET_HOME_PAGE_SPEED_INSIGHTS_SUCCESS";
export const GET_HOME_PAGE_SPEED_INSIGHTS_FAILURE =
  "page-speed-insights/GET_HOME_PAGE_SPEED_INSIGHTS_FAILURE";

export const GET_COLLECTION_PAGE_SPEED_INSIGHTS =
  "page-speed-insights/GET_COLLECTION_PAGE_SPEED_INSIGHTS";
export const GET_COLLECTION_PAGE_SPEED_INSIGHTS_SUCCESS =
  "page-speed-insights/GET_COLLECTION_PAGE_SPEED_INSIGHTS_SUCCESS";
export const GET_COLLECTION_PAGE_SPEED_INSIGHTS_FAILURE =
  "page-speed-insights/GET_COLLECTION_PAGE_SPEED_INSIGHTS_FAILURE";

export const GET_PRODUCT_PAGE_SPEED_INSIGHTS =
  "page-speed-insights/GET_PRODUCT_PAGE_SPEED_INSIGHTS";
export const GET_PRODUCT_PAGE_SPEED_INSIGHTS_SUCCESS =
  "page-speed-insights/GET_PRODUCT_PAGE_SPEED_INSIGHTS_SUCCESS";
export const GET_PRODUCT_PAGE_SPEED_INSIGHTS_FAILURE =
  "page-speed-insights/GET_PRODUCT_PAGE_SPEED_INSIGHTS_FAILURE";

export const GET_RANDOM_PRODUCT_PAGE_SPEED_INSIGHTS =
  "page-speed-insights/GET_RANDOM_PRODUCT_PAGE_SPEED_INSIGHTS";
export const GET_RANDOM_PRODUCT_PAGE_SPEED_INSIGHTS_SUCCESS =
  "page-speed-insights/GET_RANDOM_PRODUCT_PAGE_SPEED_INSIGHTS_SUCCESS";
export const GET_RANDOM_PRODUCT_PAGE_SPEED_INSIGHTS_FAILURE =
  "page-speed-insights/GET_RANDOM_PRODUCT_PAGE_SPEED_INSIGHTS_FAILURE";
