/* eslint-disable */
import React, { useContext } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { AppProvider as ShopifyAppProvider } from "@shopify/polaris";
import { AppProvider } from "autoketing-new";
import { ConnectedRouter } from "connected-react-router/immutable";
import enTranslations from "@shopify/polaris/locales/en.json";
import Cookies from "js-cookie";

import App from "@/App";

import configureStore, { history } from "./stores";
import reportWebVitals from "./reportWebVitals";
import { getUrlParams } from "./helpers";
import { AutoketingProvider, autoketingStore } from "./contexts/appProvider";
import { CrossSellProvider } from "./contexts/crossSellBar";
import { ToastProvider } from "./contexts/toastProvider";

const store = configureStore(/* provide initial state if any */);
const shopDomain = getUrlParams("shop") || "dev";
const hmac = getUrlParams("hmac");
const searchUrl = window.location.search;

function AutoketingApp() {
  const { state } = useContext(autoketingStore);
  if (shopDomain && hmac && window.location.search) {
    Cookies.set("autoketing_session", searchUrl, { expires: 30 });
  }
  return (
    <div
      data-theme={state.theme === "dark" ? "dark" : ""}
      nav-desktop={!state.isNavigationDesktop ? "hide" : ""}
    >
      <ShopifyAppProvider
        i18n={enTranslations}
        features={{ newDesignLanguage: true }}
        theme={
          state.theme === "dark" && {
            colorScheme: "dark",
          }
        }
      >
        <AppProvider>
          <ToastProvider>
            <Provider store={store}>
              <ConnectedRouter history={history}>
                <App />
              </ConnectedRouter>
            </Provider>
          </ToastProvider>
        </AppProvider>
      </ShopifyAppProvider>
    </div>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <AutoketingProvider>
      <CrossSellProvider>
        <AutoketingApp />
      </CrossSellProvider>
    </AutoketingProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
