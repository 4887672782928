export const CHANGE_SETTINGS = "site-verifications/CHANGE_SETTINGS";

export const GET_SITE_VERIFICATIONS =
  "site-verifications/GET_SITE_VERIFICATIONS";
export const GET_SITE_VERIFICATIONS_SUCCESS =
  "site-verifications/GET_SITE_VERIFICATIONS_SUCCESS";
export const GET_SITE_VERIFICATIONS_FAILURE =
  "site-verifications/GET_SITE_VERIFICATIONS_FAILURE";

export const EDIT_SITE_VERIFICATIONS =
  "site-verifications/EDIT_SITE_VERIFICATIONS";
export const EDIT_SITE_VERIFICATIONS_SUCCESS =
  "site-verifications/EDIT_SITE_VERIFICATIONS_SUCCESS";
export const EDIT_SITE_VERIFICATIONS_FAILURE =
  "site-verifications/EDIT_SITE_VERIFICATIONS_FAILURE";
