import { fromJS } from "immutable";

// types
import * as types from "../types/page-speed-insights";

const initialState = fromJS({
  loading: false,
  score: 0,
  home_loading: false,
  home_score: 0,
  collection_loading: false,
  collection_score: 0,
  product_loading: false,
  product_score: 0,
  product_handle: "",
});

export const pageSpeedInsightsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_RANDOM_PRODUCT_PAGE_SPEED_INSIGHTS_SUCCESS:
      return state.set("product_handle", action.payload.handle);
    case types.GET_PAGE_SPEED_INSIGHTS:
      return state.set("loading", true);
    case types.GET_PAGE_SPEED_INSIGHTS_SUCCESS:
      return state
        .set("loading", false)
        .set(
          "score",
          action.payload.lighthouseResult.categories.performance.score
        );
    case types.GET_HOME_PAGE_SPEED_INSIGHTS:
      return state.set("home_loading", true);
    case types.GET_HOME_PAGE_SPEED_INSIGHTS_SUCCESS:
      return state
        .set("home_loading", false)
        .set(
          "home_score",
          action.payload.lighthouseResult.categories.performance.score
        );
    case types.GET_COLLECTION_PAGE_SPEED_INSIGHTS:
      return state.set("collection_loading", true);
    case types.GET_COLLECTION_PAGE_SPEED_INSIGHTS_SUCCESS:
      return state
        .set("collection_loading", false)
        .set(
          "collection_score",
          action.payload.lighthouseResult.categories.performance.score
        );
    case types.GET_PRODUCT_PAGE_SPEED_INSIGHTS:
      return state.set("product_loading", true);
    case types.GET_PRODUCT_PAGE_SPEED_INSIGHTS_SUCCESS:
      return state
        .set("product_loading", false)
        .set(
          "product_score",
          action.payload.lighthouseResult.categories.performance.score
        );

    default:
      return state;
  }
};
