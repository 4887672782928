import { fromJS } from "immutable";

// types
import * as types from "../types/sitemap";

const initialState = fromJS({
  loading: false,
  sitemap_status: false,
  sitemap_password_status: false,
  sitemap_autoketing_url: "",
  sitemap_shop_url: "",
  sitemap_options: [],
  sitemap_limit_show: 20,
  sitemap_products: false,
});

export const sitemapReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_SETTINGS:
      return state.set(action.payload.field, action.payload.value);
    case types.GET_HTML_SITEMAP:
      return state.set("loading", true);
    case types.GET_HTML_SITEMAP_SUCCESS:
      return state
        .set("loading", false)
        .set("sitemap_status", action.payload.sitemap_status)
        .set("sitemap_autoketing_url", action.payload.sitemap_autoketing_url)
        .set("sitemap_shop_url", action.payload.sitemap_shop_url)
        .set("sitemap_options", action.payload.sitemap_options)
        .set("sitemap_password_status", action.payload.sitemap_password_status)
        .set("sitemap_limit_show", action.payload.sitemap_limit_show);

    default:
      return state;
  }
};
