import { call, put, takeEvery } from "redux-saga/effects";

// types
import * as appTypes from "@/stores/types/app";
import * as types from "@/stores/types/image-optimize";

// services
import * as ImageOptimizeService from "@/stores/services/image-optimize";

function* getImageOptimize() {
  const response = yield call(ImageOptimizeService.getImageOptimize);

  if (response) {
    yield put({
      type: types.GET_IMAGE_OPTIMIZE_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: types.GET_IMAGE_OPTIMIZE_FAILURE,
      payload: response,
    });
  }
}

function* getOptimizeByShop() {
  const response = yield call(ImageOptimizeService.getOptimizeByShop);

  if (response) {
    yield put({
      type: "GET_OPTIMIZE_BY_SHOP_SUCCESS",
      payload: response,
    });
  } else {
    yield put({
      type: "GET_OPTIMIZE_BY_SHOP_FAILURE",
      payload: response,
    });
  }
}

function* nextRunOptimizeByShop() {
  const response = yield call(ImageOptimizeService.nextRunOptimizeByShop);
  if (!response.success) {
    yield put({
      type: "NEXT_RUN_OPTIMIZE_BY_SHOP_FAILURE",
    });
  }
}

function* pauseRunOptimizeByShop() {
  const response = yield call(ImageOptimizeService.pauseRunOptimizeByShop);
  if (!response.success) {
    yield put({
      type: "PAUSE_RUN_OPTIMIZE_BY_SHOP_FAILURE",
    });
  }
}

function* nextRevertOptimizeByShop() {
  const response = yield call(ImageOptimizeService.nextRevertOptimizeByShop);
  if (!response.success) {
    yield put({
      type: "NEXT_REVERT_OPTIMIZE_BY_SHOP_FAILURE",
    });
  }
}

function* pauseRevertOptimizeByShop() {
  const response = yield call(ImageOptimizeService.pauseRevertOptimizeByShop);
  if (!response.success) {
    yield put({
      type: "PAUSE_REVERT_OPTIMIZE_BY_SHOP_FAILURE",
    });
  }
}

function* getDataImageByShop(data) {
  const response = yield call(ImageOptimizeService.getDataImageByShop, data);

  if (response) {
    yield put({
      type: "GET_DATA_IMAGE_BY_SHOP_SUCCESS",
      payload: response,
    });
  } else {
    yield put({
      type: "GET_DATA_IMAGE_BY_SHOP_FAILURE",
      payload: response,
    });
  }
}

function* setOptimizeByShop(data) {
  const response = yield call(ImageOptimizeService.setOptimizeByShop, data);
  yield put({
    type: appTypes.ALERT,
    payload: {
      success: response.success,
    },
  });
  yield put({
    type: "SET_OPTIMIZE_BY_SHOP_SUCCESS",
  });
}

function* manualRunOneImageProductByShop(data) {
  const response = yield call(
    ImageOptimizeService.manualRunOneImageProductByShop,
    data
  );
  yield put({
    type: appTypes.ALERT,
    payload: {
      success: response.success,
    },
  });
}

function* manualRevertOneImageProductByShop(data) {
  const response = yield call(
    ImageOptimizeService.manualRevertOneImageProductByShop,
    data
  );
  yield put({
    type: appTypes.ALERT,
    payload: {
      success: response.success,
    },
  });
}

function* getImageOptimizeStatus() {
  const response = yield call(ImageOptimizeService.getImageOptimize);

  if (response) {
    yield put({
      type: types.GET_IMAGE_OPTIMIZE_STATUS_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: types.GET_IMAGE_OPTIMIZE_STATUS_FAILURE,
      payload: response,
    });
  }
}

function* editImageOptimize(data) {
  const response = yield call(ImageOptimizeService.editImageOptimize, data);

  const responseImageOptimize = yield call(
    ImageOptimizeService.getImageOptimize
  );

  yield put({
    type: appTypes.ALERT,
    payload: {
      success: response.success,
    },
  });

  if (response.success === true) {
    yield put({
      type: types.EDIT_IMAGE_OPTIMIZE_SUCCESS,
      payload: responseImageOptimize,
    });
  } else {
    yield put({
      type: types.EDIT_IMAGE_OPTIMIZE_FAILURE,
      payload: response,
    });
  }
}

function* toggleImageOptimize(data) {
  const response = yield call(ImageOptimizeService.editImageOptimize, data);

  const responseImageOptimize = yield call(
    ImageOptimizeService.getImageOptimize
  );

  if (response.success === true) {
    yield put({
      type: types.TOGGLE_IMAGE_OPTIMIZE_SUCCESS,
      payload: responseImageOptimize,
    });
  } else {
    yield put({
      type: types.TOGGLE_IMAGE_OPTIMIZE_FAILURE,
      payload: response,
    });
  }
}

function* getHistoryImageOptimize(data) {
  const response = yield call(
    ImageOptimizeService.getHistoryImageOptimize,
    data
  );

  if (response) {
    yield put({
      type: types.GET_HISTORY_IMAGE_OPTIMIZE_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: types.GET_HISTORY_IMAGE_OPTIMIZE_FAILURE,
      payload: response,
    });
  }
}

function* revertImageOptimize(data) {
  const response = yield call(ImageOptimizeService.revertImageOptimize, data);

  const responseHistoryImageOptimize = yield call(
    ImageOptimizeService.getHistoryImageOptimize,
    {}
  );

  if (response.success === true) {
    yield put({
      type: appTypes.ALERT,
      payload: {
        success: response.success,
      },
    });
    yield put({
      type: types.REVERT_IMAGE_OPTIMIZE_SUCCESS,
      payload: responseHistoryImageOptimize,
    });
  } else {
    yield put({
      type: appTypes.ALERT,
      payload: {
        success: response.success,
      },
    });
    yield put({
      type: types.REVERT_IMAGE_OPTIMIZE_FAILURE,
      payload: response,
    });
  }
}

function* toggleRevertAllImageOptimize(data) {
  const response = yield call(ImageOptimizeService.editImageOptimize, data);

  const responseImageOptimize = yield call(
    ImageOptimizeService.getImageOptimize
  );

  if (response.success === true) {
    yield put({
      type: types.TOGGLE_REVERT_ALL_IMAGE_OPTIMIZE_SUCCESS,
      payload: responseImageOptimize,
    });
  } else {
    yield put({
      type: types.TOGGLE_REVERT_ALL_IMAGE_OPTIMIZE_FAILURE,
      payload: response,
    });
  }
}

function* imgOptimizeSaga() {
  yield takeEvery("GET_OPTIMIZE_BY_SHOP", getOptimizeByShop);
  yield takeEvery("NEXT_RUN_OPTIMIZE_BY_SHOP", nextRunOptimizeByShop);
  yield takeEvery("PAUSE_RUN_OPTIMIZE_BY_SHOP", pauseRunOptimizeByShop);
  yield takeEvery("NEXT_REVERT_OPTIMIZE_BY_SHOP", nextRevertOptimizeByShop);
  yield takeEvery("PAUSE_REVERT_OPTIMIZE_BY_SHOP", pauseRevertOptimizeByShop);
  yield takeEvery("GET_DATA_IMAGE_BY_SHOP", getDataImageByShop);
  yield takeEvery("SET_OPTIMIZE_BY_SHOP", setOptimizeByShop);
  yield takeEvery(
    "MANUAL_RUN_ONE_IMAGE_PRODUCT_BY_SHOP",
    manualRunOneImageProductByShop
  );
  yield takeEvery(
    "MANUAL_REVERT_ONE_IMAGE_PRODUCT_BY_SHOP",
    manualRevertOneImageProductByShop
  );
  yield takeEvery(types.GET_IMAGE_OPTIMIZE, getImageOptimize);
  yield takeEvery(types.GET_IMAGE_OPTIMIZE_STATUS, getImageOptimizeStatus);
  yield takeEvery(types.EDIT_IMAGE_OPTIMIZE, editImageOptimize);
  yield takeEvery(types.TOGGLE_IMAGE_OPTIMIZE, toggleImageOptimize);
  yield takeEvery(types.GET_HISTORY_IMAGE_OPTIMIZE, getHistoryImageOptimize);
  yield takeEvery(types.REVERT_IMAGE_OPTIMIZE, revertImageOptimize);
  yield takeEvery(
    types.TOGGLE_REVERT_ALL_IMAGE_OPTIMIZE,
    toggleRevertAllImageOptimize
  );
}

export default imgOptimizeSaga;
