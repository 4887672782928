import { fromJS } from "immutable";

// types
import * as types from "../types/app";

const initialState = fromJS({
  isSaveBar: false,
  success: null,
  status_app: false,
  shopInfo: {
    shopOwner: "",
    email: "",
    shopName: "",
    myshopifyDomain: "",
    domain: "",
  },
  listNotification: [],
  statusMenu: false,
  sitemap_password_status: false,
});

let data;

export const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SAVE_BAR:
      return state.set("isSaveBar", action.payload.isSaveBar);
    case types.ALERT:
      return state.set("success", action.payload.success);
    case types.CHANGE_STATUS_MENU:
      return state.set("statusMenu", !state.get("statusMenu"));
    case types.CHANGE_APP_STATUS:
      return state.set("status_app", !state.get("status_app"));
    case types.CHANGE_APP_STATUS_SUCCESS:
      return state.set("status_app", action.payload.status_app);
    case types.GET_LIST_NOTIFICATION_SUCCESS:
      return state.set("listNotification", fromJS(action.payload));
    case types.GET_SHOP_INFO_SUCCESS:
      data = action.payload;
      if (
        data &&
        data.myshopify_domain &&
        data.myshopify_domain.includes(".myshopify.com")
      ) {
        window.fcWidget.user.setProperties({
          firstName: data.name,
          shopOwner: data.shop_owner,
          email: data.email,
          phone: data.phone,
          timezone: data.timezone,
          shop: data.myshopify_domain,
        });
        const style = document.createElement("style");
        style.innerHTML = "#fc_frame{display: unset!important;}";
        document.body.appendChild(style);
      }

      return state
        .setIn(["shopInfo", "shopOwner"], data.shop_owner)
        .setIn(["shopInfo", "email"], data.email)
        .setIn(["shopInfo", "shopName"], data.name)
        .setIn(["shopInfo", "myshopifyDomain"], data.myshopify_domain)
        .setIn(["shopInfo", "domain"], data.domain);
    case types.GET_SETTINGS:
      return state.set("app_loading", true);
    case types.GET_SETTINGS_SUCCESS:
      return state
        .set("app_loading", false)
        .set("status_app", action.payload.status_app)
        .set("sitemap_password_status", action.payload.sitemap_password_status);

    default:
      return state;
  }
};
