export const CHANGE_SETTINGS = "image-optimize/CHANGE_SETTINGS";

export const GET_IMAGE_OPTIMIZE = "image-optimize/GET_IMAGE_OPTIMIZE";
export const GET_IMAGE_OPTIMIZE_SUCCESS =
  "image-optimize/GET_IMAGE_OPTIMIZE_SUCCESS";
export const GET_IMAGE_OPTIMIZE_FAILURE =
  "image-optimize/GET_IMAGE_OPTIMIZE_FAILURE";

export const GET_IMAGE_OPTIMIZE_STATUS =
  "image-optimize/GET_IMAGE_OPTIMIZE_STATUS";
export const GET_IMAGE_OPTIMIZE_STATUS_SUCCESS =
  "image-optimize/GET_IMAGE_OPTIMIZE_STATUS_SUCCESS";
export const GET_IMAGE_OPTIMIZE_STATUS_FAILURE =
  "image-optimize/GET_IMAGE_OPTIMIZE_STATUS_FAILURE";

export const EDIT_IMAGE_OPTIMIZE = "image-optimize/EDIT_IMAGE_OPTIMIZE";
export const EDIT_IMAGE_OPTIMIZE_SUCCESS =
  "image-optimize/EDIT_IMAGE_OPTIMIZE_SUCCESS";
export const EDIT_IMAGE_OPTIMIZE_FAILURE =
  "image-optimize/EDIT_IMAGE_OPTIMIZE_FAILURE";

export const TOGGLE_IMAGE_OPTIMIZE = "image-optimize/TOGGLE_IMAGE_OPTIMIZE";
export const TOGGLE_IMAGE_OPTIMIZE_SUCCESS =
  "image-optimize/TOGGLE_IMAGE_OPTIMIZE_SUCCESS";
export const TOGGLE_IMAGE_OPTIMIZE_FAILURE =
  "image-optimize/TOGGLE_IMAGE_OPTIMIZE_FAILURE";

export const GET_HISTORY_IMAGE_OPTIMIZE =
  "image-optimize/GET_HISTORY_IMAGE_OPTIMIZE";
export const GET_HISTORY_IMAGE_OPTIMIZE_SUCCESS =
  "image-optimize/GET_HISTORY_IMAGE_OPTIMIZE_SUCCESS";
export const GET_HISTORY_IMAGE_OPTIMIZE_FAILURE =
  "image-optimize/GET_HISTORY_IMAGE_OPTIMIZE_FAILURE";

export const REVERT_IMAGE_OPTIMIZE = "image-optimize/REVERT_IMAGE_OPTIMIZE";
export const REVERT_IMAGE_OPTIMIZE_SUCCESS =
  "image-optimize/REVERT_IMAGE_OPTIMIZE_SUCCESS";
export const REVERT_IMAGE_OPTIMIZE_FAILURE =
  "image-optimize/REVERT_IMAGE_OPTIMIZE_FAILURE";

export const TOGGLE_REVERT_ALL_IMAGE_OPTIMIZE =
  "image-optimize/TOGGLE_REVERT_ALL_IMAGE_OPTIMIZE";
export const TOGGLE_REVERT_ALL_IMAGE_OPTIMIZE_SUCCESS =
  "image-optimize/TOGGLE_REVERT_ALL_IMAGE_OPTIMIZE_SUCCESS";
export const TOGGLE_REVERT_ALL_IMAGE_OPTIMIZE_FAILURE =
  "image-optimize/TOGGLE_REVERT_ALL_IMAGE_OPTIMIZE_FAILURE";
