import { callApi } from "./service";

const dashboardApi = process.env.REACT_APP_DASHBOARD_API;

export function* getNotFoundPage() {
  const payload = {
    method: "GET",
    url: `${dashboardApi}/seo/redirect-404`,
    params: {},
  };

  return yield callApi(payload);
}

export function* editStatusNotFoundPage(data) {
  const payload = {
    method: "PATCH",
    url: `${dashboardApi}/seo/redirect-404`,
    params: data.payload,
  };
  return yield callApi(payload);
}

export function* editNotFoundPage(data) {
  const payload = {
    method: "POST",
    url: `${dashboardApi}/seo/redirect-404`,
    params: data.payload,
  };
  return yield callApi(payload);
}

export function* getListNotFoundPage(data) {
  const payload = {
    method: "GET",
    url: `${dashboardApi}/seo/redirect-404-logging`,
    params: data.payload,
  };

  return yield callApi(payload);
}

export function* deleteNotFoundPage(data) {
  const payload = {
    method: "DELETE",
    url: `${dashboardApi}/seo/redirect-404-logging`,
    params: data.payload,
  };
  return yield callApi(payload);
}
