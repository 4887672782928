import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Spinner, Stack } from "@shopify/polaris";

// styles
import "@/styles/app.scss";

// configs
import { PATH_NAME } from "@/configs";

// routes
import PrivateRoute from "@/routes/PrivateRoute";

// pages
const Login = lazy(() => import("@/pages/Login"));

const TheContent = lazy(() => import("@/components/Layout"));

export const App = () => {
  return (
    <Router>
      <Suspense
        fallback={
          <Stack distribution="center">
            <Spinner
              accessibilityLabel="Loading..."
              size="small"
              color="teal"
            />
          </Stack>
        }
      >
        <Switch>
          <Route exact path={PATH_NAME.LOGIN} component={Login} />
          <PrivateRoute path="/" component={TheContent} />
        </Switch>
      </Suspense>
    </Router>
  );
};

export default App;
