import { call, put, takeEvery } from "redux-saga/effects";

// types
import * as appTypes from "@/stores/types/app";
import * as types from "@/stores/types/sitemap";

// services
import * as SitemapService from "@/stores/services/sitemap";

function* getHTMLSitemap() {
  const response = yield call(SitemapService.getHTMLSitemap);

  if (response) {
    yield put({
      type: types.GET_HTML_SITEMAP_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: types.GET_HTML_SITEMAP_FAILURE,
      payload: response,
    });
  }
}

function* editHTMLSitemap(data) {
  const response = yield call(SitemapService.editHTMLSitemap, data);

  if (response.success === true) {
    yield put({
      type: appTypes.ALERT,
      payload: {
        success: response.success,
      },
    });
    yield put({
      type: types.EDIT_HTML_SITEMAP_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: appTypes.ALERT,
      payload: {
        success: response.success,
      },
    });
    yield put({
      type: types.EDIT_HTML_SITEMAP_FAILURE,
      payload: response,
    });
  }
}

function* htmlSitemapSaga() {
  yield takeEvery(types.GET_HTML_SITEMAP, getHTMLSitemap);
  yield takeEvery(types.EDIT_HTML_SITEMAP, editHTMLSitemap);
}

export default htmlSitemapSaga;
