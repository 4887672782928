import { call, put, takeEvery } from "redux-saga/effects";

// types
import * as types from "@/stores/types/app";

// services
import * as AppService from "@/stores/services/app";

export function* changeAppStatus(data) {
  const response = yield call(AppService.changeStatus, data);
  const responseGet = yield call(AppService.getStatus);

  if (response.success === true) {
    yield put({
      type: types.ALERT,
      payload: {
        success: response.success,
      },
    });
    yield put({
      type: types.CHANGE_APP_STATUS_SUCCESS,
      payload: responseGet,
    });
  } else {
    yield put({
      type: types.ALERT,
      payload: {
        success: response.success,
      },
    });
    yield put({
      type: types.CHANGE_APP_STATUS_FAILURE,
      payload: response,
    });
  }
}

export function* getShopInfo(payload) {
  const response = yield call(AppService.getShopInfo, payload.shop);

  if (response) {
    yield put({
      type: types.GET_SHOP_INFO_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: types.GET_SHOP_INFO_FAILURE,
      payload: response,
    });
  }
}

export function* getListNotification(data) {
  const response = yield call(AppService.getListNotification, data.shop);

  if (response) {
    yield put({
      type: types.GET_LIST_NOTIFICATION_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: types.GET_LIST_NOTIFICATION_FAILURE,
      payload: response,
    });
  }
}

export function* getAppSettings() {
  const response = yield call(AppService.getAppSettings);

  if (response) {
    yield put({
      type: types.GET_SETTINGS_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: types.GET_SETTINGS_FAILURE,
      payload: response,
    });
  }
}

function* appSaga() {
  yield takeEvery(types.CHANGE_APP_STATUS, changeAppStatus);
  yield takeEvery(types.GET_SHOP_INFO, getShopInfo);
  yield takeEvery(types.GET_LIST_NOTIFICATION, getListNotification);
  yield takeEvery(types.GET_SETTINGS, getAppSettings);
}

export default appSaga;
