import axios from "axios";
import Cookies from "js-cookie";

export function* callApi(payload) {
  const url = payload.url;
  const method = payload.method;
  const params = payload.params;
  // POST
  if (method === "POST") {
    if (typeof params !== "object") {
      return yield;
    }
    const Array = Object.keys(params);
    const bodyFormData = new FormData();
    yield Array.forEach(element => {
      bodyFormData.append(element, params[element]);
    });
    return yield axios({
      method,
      url,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: Cookies.get("autoketing_session"),
      },
    })
      .then(response => {
        return response.status === 200 ? response.data : "";
      })
      .catch(error => {
        console.log(error);
      });
  }

  // PUT
  if (method === "PUT") {
    if (typeof params !== "object") {
      return yield;
    }
    const Array = Object.keys(params);
    const bodyFormData = new FormData();
    yield Array.forEach(element => {
      bodyFormData.append(element, params[element]);
    });
    return yield axios({
      method,
      url,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: Cookies.get("autoketing_session"),
      },
    })
      .then(response => {
        return response.status === 200 ? response.data : "";
      })
      .catch(error => {
        console.log(error);
      });
  }

  // PATCH
  if (method === "PATCH") {
    if (typeof params !== "object") {
      return yield;
    }
    const Array = Object.keys(params);
    const bodyFormData = new FormData();
    yield Array.forEach(element => {
      bodyFormData.append(element, params[element]);
    });
    return yield axios({
      method,
      url,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: Cookies.get("autoketing_session"),
      },
    })
      .then(response => {
        return response.status === 200 ? response.data : "";
      })
      .catch(error => {
        console.log(error);
      });
  }

  // DELETE
  if (method === "DELETE") {
    if (typeof params !== "object") {
      return yield;
    }
    const Array = Object.keys(params);
    const bodyFormData = new FormData();
    yield Array.forEach(element => {
      bodyFormData.append(element, params[element]);
    });
    return yield axios({
      method,
      url,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: Cookies.get("autoketing_session"),
      },
    })
      .then(response => {
        return response.status === 200 ? response.data : "";
      })
      .catch(error => {
        console.log(error);
      });
  }

  // GET
  return yield axios({
    method: "get",
    url,
    params,
    headers: {
      Authorization: Cookies.get("autoketing_session"),
    },
  })
    .then(response => {
      return response.status === 200 ? response.data : [];
    })
    .catch(() => {
      return [];
    });
}

export function* callApiJSON({ url, method, params }) {
  if (method === "POST" || method === "PUT" || method === "PATCH") {
    return yield axios({
      method,
      url,
      data: params,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: Cookies.get("autoketing_session"),
      },
    })
      .then(response => {
        return response.status === 200 ? response.data : "";
      })
      .catch(error => {
        console.log(error);
      });
  }

  // DELETE
  if (method === "DELETE") {
    if (typeof params !== "object") {
      return yield;
    }
    return yield axios({
      method,
      url,
      params,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: Cookies.get("autoketing_session"),
      },
    })
      .then(response => {
        return response.status === 200 ? response.data : "";
      })
      .catch(error => {
        console.log(error);
      });
  }

  // GET
  return yield axios({
    method: "get",
    url,
    params,
    headers: {
      Authorization: Cookies.get("autoketing_session"),
    },
  })
    .then(response => {
      return response.status === 200 ? response.data : [];
    })
    .catch(() => {
      return [];
    });
}
