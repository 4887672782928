/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";

// configs
import { PATH_NAME } from "@/configs";

// contexts
import { autoketingStore } from "@/contexts/appProvider";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { state } = useContext(autoketingStore);

  return (
    <Route
      {...rest}
      render={props => {
        if (state.isLogin) {
          return <Component {...props} />;
        }

        return (
          <Redirect
            to={{
              pathname: PATH_NAME.LOGIN,
              state: {
                from: props.location,
              },
            }}
          />
        );
      }}
    />
  );
};

export default PrivateRoute;
