import { call, put, takeEvery } from "redux-saga/effects";

// types
import * as types from "@/stores/types/page-speed-insights";

// services
import * as PageSpeedInsightsService from "@/stores/services/page-speed-insights";

function* getPageSpeedInsights(data) {
  const response = yield call(
    PageSpeedInsightsService.getPageSpeedInsights,
    data
  );

  if (response) {
    yield put({
      type: types.GET_PAGE_SPEED_INSIGHTS_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: types.GET_PAGE_SPEED_INSIGHTS_FAILURE,
      payload: response,
    });
  }
}

function* getHomePageSpeedInsights(data) {
  const response = yield call(
    PageSpeedInsightsService.getHomePageSpeedInsights,
    data
  );

  if (response) {
    yield put({
      type: types.GET_HOME_PAGE_SPEED_INSIGHTS_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: types.GET_HOME_PAGE_SPEED_INSIGHTS_FAILURE,
      payload: response,
    });
  }
}

function* getCollectionPageSpeedInsights(data) {
  const response = yield call(
    PageSpeedInsightsService.getCollectionPageSpeedInsights,
    data
  );

  if (response) {
    yield put({
      type: types.GET_COLLECTION_PAGE_SPEED_INSIGHTS_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: types.GET_COLLECTION_PAGE_SPEED_INSIGHTS_FAILURE,
      payload: response,
    });
  }
}

function* getProductPageSpeedInsights(data) {
  const response = yield call(
    PageSpeedInsightsService.getProductPageSpeedInsights,
    data
  );

  if (response) {
    yield put({
      type: types.GET_PRODUCT_PAGE_SPEED_INSIGHTS_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: types.GET_PRODUCT_PAGE_SPEED_INSIGHTS_FAILURE,
      payload: response,
    });
  }
}

function* getRandomProductPageSpeedInsights(data) {
  const response = yield call(
    PageSpeedInsightsService.getRandomProductPageSpeedInsights,
    data
  );

  if (response) {
    yield put({
      type: types.GET_RANDOM_PRODUCT_PAGE_SPEED_INSIGHTS_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: types.GET_RANDOM_PRODUCT_PAGE_SPEED_INSIGHTS_FAILURE,
      payload: response,
    });
  }
}

function* pageSpeedInsightsSaga() {
  yield takeEvery(types.GET_PAGE_SPEED_INSIGHTS, getPageSpeedInsights);
  yield takeEvery(types.GET_HOME_PAGE_SPEED_INSIGHTS, getHomePageSpeedInsights);
  yield takeEvery(
    types.GET_COLLECTION_PAGE_SPEED_INSIGHTS,
    getCollectionPageSpeedInsights
  );
  yield takeEvery(
    types.GET_PRODUCT_PAGE_SPEED_INSIGHTS,
    getProductPageSpeedInsights
  );
  yield takeEvery(
    types.GET_RANDOM_PRODUCT_PAGE_SPEED_INSIGHTS,
    getRandomProductPageSpeedInsights
  );
}

export default pageSpeedInsightsSaga;
