export const SAVE_BAR = "app/SAVE_BAR";
export const ALERT = "app/ALERT";
export const GET_SETTINGS = "app/GET_SETTINGS";
export const GET_SETTINGS_SUCCESS = "app/GET_SETTINGS_SUCCESS";
export const GET_SETTINGS_FAILURE = "app/GET_SETTINGS_FAILURE";

export const CHANGE_APP_STATUS = "app/CHANGE_APP_STATUS";
export const CHANGE_APP_STATUS_SUCCESS = "app/CHANGE_APP_STATUS_SUCCESS";
export const CHANGE_APP_STATUS_FAILURE = "app/CHANGE_APP_STATUS_FAILURE";

export const GET_LIST_NOTIFICATION = "app/GET_LIST_NOTIFICATION";
export const GET_LIST_NOTIFICATION_SUCCESS =
  "app/GET_LIST_NOTIFICATION_SUCCESS";
export const GET_LIST_NOTIFICATION_FAILURE =
  "app/GET_LIST_NOTIFICATION_FAILURE";

export const CHANGE_STATUS_MENU = "app/CHANGE_STATUS_MENU";
export const UPDATE_NOTIFICATION = "app/UPDATE_NOTIFICATION";
export const GET_NOTIFICATION_DETAIL = "app/GET_NOTIFICATION_DETAIL";

export const GET_SHOP_INFO = "app/GET_SHOP_INFO";
export const GET_SHOP_INFO_SUCCESS = "app/GET_SHOP_INFO_SUCCESS";
export const GET_SHOP_INFO_FAILURE = "app/GET_SHOP_INFO_FAILURE";
