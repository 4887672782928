import { call, put, takeEvery } from "redux-saga/effects";

// types
import * as appTypes from "@/stores/types/app";
import * as types from "@/stores/types/google";

// services
import * as GoogleService from "@/stores/services/google";

function* getGoogleSearchConsole() {
  const response = yield call(GoogleService.getGoogleSearchConsole);

  if (response) {
    yield put({
      type: types.GET_GOOGLE_SEARCH_CONSOLE_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: types.GET_GOOGLE_SEARCH_CONSOLE_FAILURE,
      payload: response,
    });
  }
}

function* editGoogleSearchConsole(data) {
  const response = yield call(GoogleService.editGoogleSearchConsole, data);

  if (response.success === true) {
    yield put({
      type: appTypes.ALERT,
      payload: {
        success: response.success,
      },
    });
    yield put({
      type: types.EDIT_GOOGLE_SEARCH_CONSOLE_SUCCESS,
      payload: data.payload,
    });
  } else {
    yield put({
      type: appTypes.ALERT,
      payload: {
        success: response.success,
      },
    });
    yield put({
      type: types.EDIT_GOOGLE_SEARCH_CONSOLE_FAILURE,
      payload: response,
    });
  }
}

function* getPerformance(data) {
  const response = yield call(GoogleService.getPerformance, data);

  if (response) {
    yield put({
      type: types.GET_PERFORMANCE_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: types.GET_PERFORMANCE_FAILURE,
      payload: response,
    });
  }
}

function* getSitemaps(data) {
  const response = yield call(GoogleService.getSitemaps, data);

  if (response) {
    yield put({
      type: types.GET_SITEMAPS_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: types.GET_SITEMAPS_FAILURE,
      payload: response,
    });
  }
}

function* submitSitemap(data) {
  const response = yield call(GoogleService.submitSitemap, data);

  if (response.success === true) {
    yield put({
      type: appTypes.ALERT,
      payload: {
        success: response.success,
      },
    });
    yield put({
      type: types.SUBMIT_SITEMAP_SUCCESS,
      payload: data.payload,
    });
    yield put({
      type: types.GET_SITEMAPS,
    });
  } else {
    yield put({
      type: appTypes.ALERT,
      payload: {
        success: response.success,
      },
    });
    yield put({
      type: types.SUBMIT_SITEMAP_FAILURE,
      payload: response,
    });
  }
}

function* deleteSitemap(data) {
  const response = yield call(GoogleService.deleteSitemap, data);

  if (response.success === true) {
    yield put({
      type: appTypes.ALERT,
      payload: {
        success: response.success,
      },
    });
    yield put({
      type: types.DELETE_SITEMAP_SUCCESS,
      payload: data.payload,
    });
  } else {
    yield put({
      type: appTypes.ALERT,
      payload: {
        success: response.success,
      },
    });
    yield put({
      type: types.DELETE_SITEMAP_FAILURE,
      payload: response,
    });
  }
}

function* googleSearchConsoleSaga() {
  yield takeEvery(types.GET_GOOGLE_SEARCH_CONSOLE, getGoogleSearchConsole);
  yield takeEvery(types.EDIT_GOOGLE_SEARCH_CONSOLE, editGoogleSearchConsole);
  yield takeEvery(types.GET_PERFORMANCE, getPerformance);
  yield takeEvery(types.GET_SITEMAPS, getSitemaps);
  yield takeEvery(types.SUBMIT_SITEMAP, submitSitemap);
  yield takeEvery(types.DELETE_SITEMAP, deleteSitemap);
}

export default googleSearchConsoleSaga;
