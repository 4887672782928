import { callApi } from "./service";

const dashboardApi = process.env.REACT_APP_DASHBOARD_API;

export function* getSiteVerifications() {
  const payload = {
    method: "GET",
    url: `${dashboardApi}/seo/site-verifications`,
    params: {},
  };
  return yield callApi(payload);
}

export function* editSiteVerifications(data) {
  const payload = {
    method: "PATCH",
    url: `${dashboardApi}/seo/site-verifications`,
    params: data.payload,
  };
  return yield callApi(payload);
}
