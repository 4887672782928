/* eslint-disable no-restricted-syntax */
import { applyMiddleware, compose, createStore } from "redux";
import { createBrowserHistory } from "history";
import createSagaMiddleware from "redux-saga";
import { routerMiddleware } from "connected-react-router/immutable";
import { Iterable } from "immutable";
import rootSaga from "./sagas";
import rootReducer from "./reducers";

export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();

const middleWares = [];
middleWares.push(sagaMiddleware);

if (process.env.NODE_ENV === `development`) {
  // eslint-disable-next-line global-require
  const { createLogger } = require(`redux-logger`);
  const logger = createLogger({
    stateTransformer: state => {
      const newState = {};
      const stateObj = state.toObject();

      // eslint-disable-next-line no-unused-vars
      for (const i of Object.keys(stateObj)) {
        if (Iterable.isIterable(stateObj[i])) {
          newState[i] = stateObj[i].toJS();
        } else {
          newState[i] = stateObj[i];
        }
      }

      return newState;
    },
  });
  middleWares.push(logger);
}

export default function configureStore(preloadedState) {
  const store = createStore(
    rootReducer(history), // root reducer with router state
    preloadedState,
    compose(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        ...middleWares
      )
    )
  );
  // then run the saga
  sagaMiddleware.run(rootSaga);
  return store;
}
