import React, { createContext, useState } from "react";
import { Toast } from "autoketing-new";

const toastStore = createContext();
const { Provider } = toastStore;

const ToastProvider = ({ children }) => {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastError, setToastError] = useState(false);

  const handleShowToast = (message, error = false) => {
    setShowToast(true);
    setToastMessage(message);
    setToastError(error);
  };

  const toggleToast = () => {
    setShowToast(showToast => !showToast);
  };

  return (
    <Provider value={{ handleShowToast }}>
      <Toast
        title={toastError ? "Error" : "Success"}
        open={showToast}
        content={toastMessage}
        error={toastError}
        onDismiss={toggleToast}
      />
      {children}
    </Provider>
  );
};

export { toastStore, ToastProvider };
