/* eslint-disable func-names */
import React, { createContext, useEffect, useReducer } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { getParamsUrlString } from "../../helpers";

const api = process.env.REACT_APP_API_COMMON_SERVICE;

const initialState = {
  isBar: false,
  crossData: {},
};

function reducer(state, action) {
  switch (action.type) {
    case "CHANGE_CROSS_SELL_BAR":
      return {
        ...state,
        isBar: true,
        crossData: action.data,
      };
    default:
      return state;
  }
}

const crossSellStore = createContext(initialState);
const { Provider } = crossSellStore;

const CrossSellProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const session = Cookies.get("autoketing_session");

  const shopDomain = getParamsUrlString(session, "shop");

  useEffect(() => {
    if (shopDomain) {
      const checkTimer = Cookies.get("autoketing-cross-sell-bar");
      const countView = ads_app_code => {
        axios({
          method: "get",
          url: `${api}/ak/sdk-admin-count-report`,
          params: {
            app_code: "seo",
            count_type: "view_ads_banner",
            send_from: "dashboard",
            shop_domain: shopDomain,
            click_ads_to_app_code: ads_app_code,
          },
        });
      };

      if (!checkTimer) {
        const apiUrl = `${api}/ak/ads-get-cross-sell`;
        axios({
          method: "get",
          url: apiUrl,
          params: {
            app_code: "seo",
          },
        }).then(function (res) {
          if (res.data && res.data.ads_status && res.data.ads_app_name) {
            countView(res.data.ads_app_code);
            dispatch({ type: "CHANGE_CROSS_SELL_BAR", data: res.data });
          }
        });
      }
    }
  }, [shopDomain]);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { crossSellStore, CrossSellProvider };
