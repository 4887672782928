import { call, put, takeEvery } from "redux-saga/effects";

// types
import * as appTypes from "@/stores/types/app";
import * as types from "@/stores/types/site-verifications";

// services
import * as SiteVerificationsService from "@/stores/services/site-verifications";

function* getSiteVerifications() {
  const response = yield call(SiteVerificationsService.getSiteVerifications);

  if (response) {
    yield put({
      type: types.GET_SITE_VERIFICATIONS_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: types.GET_SITE_VERIFICATIONS_FAILURE,
      payload: response,
    });
  }
}

function* editSiteVerifications(data) {
  const response = yield call(
    SiteVerificationsService.editSiteVerifications,
    data
  );

  if (response.success === true) {
    yield put({
      type: appTypes.ALERT,
      payload: {
        success: response.success,
      },
    });
    yield put({
      type: types.EDIT_SITE_VERIFICATIONS_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: appTypes.ALERT,
      payload: {
        success: response.success,
      },
    });
    yield put({
      type: types.EDIT_SITE_VERIFICATIONS_FAILURE,
      payload: response,
    });
  }
}

function* siteVerificationsSaga() {
  yield takeEvery(types.GET_SITE_VERIFICATIONS, getSiteVerifications);
  yield takeEvery(types.EDIT_SITE_VERIFICATIONS, editSiteVerifications);
}

export default siteVerificationsSaga;
