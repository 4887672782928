export const CHANGE_SETTINGS = "google-search-console/CHANGE_SETTINGS";

export const GET_GOOGLE_SEARCH_CONSOLE =
  "google-search-console/GET_GOOGLE_SEARCH_CONSOLE";
export const GET_GOOGLE_SEARCH_CONSOLE_SUCCESS =
  "google-search-console/GET_GOOGLE_SEARCH_CONSOLE_SUCCESS";
export const GET_GOOGLE_SEARCH_CONSOLE_FAILURE =
  "google-search-console/GET_GOOGLE_SEARCH_CONSOLE_FAILURE";

export const EDIT_GOOGLE_SEARCH_CONSOLE =
  "google-search-console/EDIT_GOOGLE_SEARCH_CONSOLE";
export const EDIT_GOOGLE_SEARCH_CONSOLE_SUCCESS =
  "google-search-console/EDIT_GOOGLE_SEARCH_CONSOLE_SUCCESS";
export const EDIT_GOOGLE_SEARCH_CONSOLE_FAILURE =
  "google-search-console/EDIT_GOOGLE_SEARCH_CONSOLE_FAILURE";

export const GET_PERFORMANCE = "google-search-console/GET_PERFORMANCE";
export const GET_PERFORMANCE_SUCCESS =
  "google-search-console/GET_PERFORMANCE_SUCCESS";
export const GET_PERFORMANCE_FAILURE =
  "google-search-console/GET_PERFORMANCE_FAILURE";

export const GET_SITEMAPS = "google-search-console/GET_SITEMAPS";
export const GET_SITEMAPS_SUCCESS =
  "google-search-console/GET_SITEMAPS_SUCCESS";
export const GET_SITEMAPS_FAILURE =
  "google-search-console/GET_SITEMAPS_FAILURE";

export const SUBMIT_SITEMAP = "google-search-console/SUBMIT_SITEMAP";
export const SUBMIT_SITEMAP_SUCCESS =
  "google-search-console/SUBMIT_SITEMAP_SUCCESS";
export const SUBMIT_SITEMAP_FAILURE =
  "google-search-console/SUBMIT_SITEMAP_FAILURE";

export const DELETE_SITEMAP = "google-search-console/DELETE_SITEMAP";
export const DELETE_SITEMAP_SUCCESS =
  "google-search-console/DELETE_SITEMAP_SUCCESS";
export const DELETE_SITEMAP_FAILURE =
  "google-search-console/DELETE_SITEMAP_FAILURE";
