import { call, put, takeEvery } from "redux-saga/effects";

// types
import * as appTypes from "@/stores/types/app";
import * as types from "@/stores/types/not-found-pages";

// services
import * as NotFoundPagesService from "@/stores/services/not-found-pages";

function* getNotFoundPage() {
  const response = yield call(NotFoundPagesService.getNotFoundPage);

  if (response) {
    yield put({
      type: types.GET_NOT_FOUND_PAGE_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: types.GET_NOT_FOUND_PAGE_FAILURE,
      payload: response,
    });
  }
}

function* editStatusNotFoundPage(data) {
  const response = yield call(
    NotFoundPagesService.editStatusNotFoundPage,
    data
  );

  if (response.success === true) {
    yield put({
      type: appTypes.ALERT,
      payload: {
        success: response.success,
      },
    });
    yield put({
      type: types.EDIT_STATUS_NOT_FOUND_PAGE_SUCCESS,
      payload: data.payload,
    });
  } else {
    yield put({
      type: appTypes.ALERT,
      payload: {
        success: response.success,
      },
    });
    yield put({
      type: types.EDIT_STATUS_NOT_FOUND_PAGE_FAILURE,
      payload: response,
    });
  }
}

function* editNotFoundPage(data) {
  const response = yield call(NotFoundPagesService.editNotFoundPage, data);

  if (response.success === true) {
    yield put({
      type: appTypes.ALERT,
      payload: {
        success: response.success,
      },
    });
    yield put({
      type: types.EDIT_NOT_FOUND_PAGE_SUCCESS,
      payload: data.payload,
    });
  } else {
    yield put({
      type: appTypes.ALERT,
      payload: {
        success: response.success,
      },
    });
    yield put({
      type: types.EDIT_NOT_FOUND_PAGE_FAILURE,
      payload: response,
    });
  }
}

function* getListNotFoundPage(data) {
  const response = yield call(NotFoundPagesService.getListNotFoundPage, data);

  if (response) {
    yield put({
      type: types.GET_LIST_NOT_FOUND_PAGE_SUCCESS,
      payload: response,
    });
  } else {
    yield put({
      type: types.GET_LIST_NOT_FOUND_PAGE_FAILURE,
      payload: response,
    });
  }
}

function* deleteNotFoundPage(data) {
  const response = yield call(NotFoundPagesService.deleteNotFoundPage, data);

  if (response.success === true) {
    yield put({
      type: appTypes.ALERT,
      payload: {
        success: response.success,
      },
    });
    yield put({
      type: types.DELETE_NOT_FOUND_PAGE_SUCCESS,
      payload: data.payload,
    });
  } else {
    yield put({
      type: appTypes.ALERT,
      payload: {
        success: response.success,
      },
    });
    yield put({
      type: types.DELETE_NOT_FOUND_PAGE_FAILURE,
      payload: response,
    });
  }
}

function* imgOptimizeSaga() {
  yield takeEvery(types.GET_NOT_FOUND_PAGE, getNotFoundPage);
  yield takeEvery(types.EDIT_STATUS_NOT_FOUND_PAGE, editStatusNotFoundPage);
  yield takeEvery(types.EDIT_NOT_FOUND_PAGE, editNotFoundPage);
  yield takeEvery(types.GET_LIST_NOT_FOUND_PAGE, getListNotFoundPage);
  yield takeEvery(types.DELETE_NOT_FOUND_PAGE, deleteNotFoundPage);
}

export default imgOptimizeSaga;
