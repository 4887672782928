/* eslint-disable no-case-declarations */
import { fromJS } from "immutable";

// types
import * as types from "../types/meta-tags";

const initialState = fromJS({
  loading: false,
  status_meta_tag: false,
  product_meta_title: "{{ product.title }}",
  product_meta_description:
    "Big promotion for you when buying {{ product.title }} today. One of the best selling {{ product.type }} in the market. Limited number of products. Hurry up!",
  collection_meta_title: "{{ collection.title }}",
  collection_meta_description:
    "Are you ready to welcome the latest {{ collection.title }} at {{ shop.name }}? {{ collection.title }} promises to bring you an extremely wonderful experience. Check it out now!",
  article_meta_title: "{{ article.title }}",
  article_meta_description:
    "Update with the latest fashion trends with interesting information in {{ article.title }} at {{ shop.name }} right now! What are you waiting for?",
  data: {},
});

export const metaTagsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_SETTINGS:
      return state.set(action.payload.field, action.payload.value);
    case types.GET_META_TAGS:
      return state.set("loading", true);
    case types.GET_META_TAGS_SUCCESS:
      const shop = Object.fromEntries(
        Object.entries(action.payload.shop).map(([key, value]) => [
          `shop.${key}`,
          value,
        ])
      );
      const product = Object.fromEntries(
        Object.entries(action.payload.product).map(([key, value]) => [
          `product.${key}`,
          value,
        ])
      );
      const collection = Object.fromEntries(
        Object.entries(action.payload.collection).map(([key, value]) => [
          `collection.${key}`,
          value,
        ])
      );
      const article = Object.fromEntries(
        Object.entries(action.payload.article).map(([key, value]) => [
          `article.${key}`,
          value,
        ])
      );
      return state
        .set("loading", false)
        .set("status_meta_tag", action.payload.status_meta_tag)
        .set("shop", action.payload.shop)
        .set("product_meta_title", action.payload.product_meta_title)
        .set(
          "product_meta_description",
          action.payload.product_meta_description
        )
        .set("collection_meta_title", action.payload.collection_meta_title)
        .set(
          "collection_meta_description",
          action.payload.collection_meta_description
        )
        .set("article_meta_title", action.payload.article_meta_title)
        .set(
          "article_meta_description",
          action.payload.article_meta_description
        )
        .set("data", {
          ...shop,
          ...product,
          ...collection,
          ...article,
        });
    case types.EDIT_META_TAG_SUCCESS:
      return state.set("status_meta_tag", action.payload.status_meta_tag);
    case types.EDIT_META_TAG_FAILURE:
      return state.set("status_meta_tag", false);

    default:
      return state;
  }
};
