export const CHANGE_SETTINGS = "not-found-pages/CHANGE_SETTINGS";

export const GET_NOT_FOUND_PAGE = "not-found-pages/GET_NOT_FOUND_PAGE";
export const GET_NOT_FOUND_PAGE_SUCCESS =
  "not-found-pages/GET_NOT_FOUND_PAGE_SUCCESS";
export const GET_NOT_FOUND_PAGE_FAILURE =
  "not-found-pages/GET_NOT_FOUND_PAGE_FAILURE";

export const EDIT_STATUS_NOT_FOUND_PAGE =
  "not-found-pages/EDIT_STATUS_NOT_FOUND_PAGE";
export const EDIT_STATUS_NOT_FOUND_PAGE_SUCCESS =
  "not-found-pages/EDIT_STATUS_NOT_FOUND_PAGE_SUCCESS";
export const EDIT_STATUS_NOT_FOUND_PAGE_FAILURE =
  "not-found-pages/EDIT_STATUS_NOT_FOUND_PAGE_FAILURE";

export const EDIT_NOT_FOUND_PAGE = "not-found-pages/EDIT_NOT_FOUND_PAGE";
export const EDIT_NOT_FOUND_PAGE_SUCCESS =
  "not-found-pages/EDIT_NOT_FOUND_PAGE_SUCCESS";
export const EDIT_NOT_FOUND_PAGE_FAILURE =
  "not-found-pages/EDIT_NOT_FOUND_PAGE_FAILURE";

export const GET_LIST_NOT_FOUND_PAGE =
  "not-found-pages/GET_LIST_NOT_FOUND_PAGE";
export const GET_LIST_NOT_FOUND_PAGE_SUCCESS =
  "not-found-pages/GET_LIST_NOT_FOUND_PAGE_SUCCESS";
export const GET_LIST_NOT_FOUND_PAGE_FAILURE =
  "not-found-pages/GET_LIST_NOT_FOUND_PAGE_FAILURE";

export const DELETE_NOT_FOUND_PAGE = "not-found-pages/DELETE_NOT_FOUND_PAGE";
export const DELETE_NOT_FOUND_PAGE_SUCCESS =
  "not-found-pages/DELETE_NOT_FOUND_PAGE_SUCCESS";
export const DELETE_NOT_FOUND_PAGE_FAILURE =
  "not-found-pages/DELETE_NOT_FOUND_PAGE_FAILURE";
