export const CHANGE_SETTINGS = "html-sitemap/CHANGE_SETTINGS";

export const GET_HTML_SITEMAP = "html-sitemap/GET_HTML_SITEMAP";
export const GET_HTML_SITEMAP_SUCCESS = "html-sitemap/GET_HTML_SITEMAP_SUCCESS";
export const GET_HTML_SITEMAP_FAILURE = "html-sitemap/GET_HTML_SITEMAP_FAILURE";

export const EDIT_HTML_SITEMAP = "html-sitemap/EDIT_HTML_SITEMAP";
export const EDIT_HTML_SITEMAP_SUCCESS =
  "html-sitemap/EDIT_HTML_SITEMAP_SUCCESS";
export const EDIT_HTML_SITEMAP_FAILURE =
  "html-sitemap/EDIT_HTML_SITEMAP_FAILURE";
