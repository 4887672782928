import React, { createContext, useEffect, useReducer } from "react";
import Cookies from "js-cookie";
import { getParamsUrlString } from "../../helpers";

const initialState = {
  isLogin: true,
  isNavigationMobile: false,
  isNavigationDesktop: true,
  shop: "",
  theme: "light",
  themeCollapsed: false,
  isReviewus: false,
  isReviewusHome: false,
  isDropNav: false,
  isWellcomePopup: false,
};

function reducer(state, action) {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        isLogin: true,
        shop: action.shop,
      };
    case "CHANGE_THEME":
      localStorage.setItem("theme", action.theme);
      return {
        ...state,
        theme: action.theme,
      };
    case "LOGOUT":
      Cookies.remove("autoketing_session");
      return { ...state, isLogin: false };
    case "TONGGLE_NAVIGATION_MOBILE":
      return { ...state, isNavigationMobile: !state.isNavigationMobile };
    case "TONGGLE_NAVIGATION_DESKTOP":
      return {
        ...state,
        isNavigationDesktop: !state.isNavigationDesktop,
      };
    case "TONGGLE_REVIEWUS":
      return { ...state, isReviewus: !state.isReviewus };
    case "TONGGLE_DROP_NAV":
      return { ...state, isDropNav: action.value };
    case "TONGGLE_REVIEWUS_HOME":
      return { ...state, isReviewusHome: !state.isReviewusHome };
    case "TONGGLE_WELCOME_POPUP":
      return { ...state, isWellcomePopup: !state.isWellcomePopup };
    case "TONGGLE_NAVIGATION_COLLAPSED":
      localStorage.setItem("autoketing-theme-layout", action.value);
      return {
        ...state,
        isNavigationDesktop: !action.value,
        themeCollapsed: action.value,
      };
    default:
      return state;
  }
}

const autoketingStore = createContext(initialState);
const { Provider } = autoketingStore;

const AutoketingProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {
    const session = Cookies.get("autoketing_session");
    if (
      session &&
      session.indexOf("hmac") >= 0 &&
      session.indexOf("shop") >= 0
    ) {
      dispatch({
        type: "LOGIN",
        shop: getParamsUrlString(session, "shop"),
      });
    } else {
      dispatch({ type: "LOGOUT" });
    }
    const theme = localStorage.getItem("theme");
    if (theme) {
      dispatch({ type: "CHANGE_THEME", theme });
    }
    const layout = localStorage.getItem("autoketing-theme-layout");
    if (layout === "true") {
      dispatch({ type: "TONGGLE_NAVIGATION_COLLAPSED", value: true });
    }
  }, []);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { autoketingStore, AutoketingProvider };
