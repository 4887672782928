/* eslint-disable no-case-declarations */
import { fromJS } from "immutable";

// types
import * as types from "../types/google";

const initialState = fromJS({
  loading: false,
  filter_loading: false,
  sitemap_loading: false,
  sitemap_delete_loading: false,
  google_search_console_status: false,
  google_search_console_list_website: "",
  google_search_console_website: "",
  rows: [],
  path: "",
  sitemap: [],
  page: 0,
});

export const googleReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_SETTINGS:
      return state.set(action.payload.field, action.payload.value);
    case types.GET_GOOGLE_SEARCH_CONSOLE:
    case types.EDIT_GOOGLE_SEARCH_CONSOLE:
      if (action.payload) {
        const gg_status = action.payload.google_search_console_status;

        if (gg_status === true || gg_status === false) {
          return state.set("loading", true);
        }
        return state.set("loading", false);
      }
      return state.set("loading", true);
    case types.GET_GOOGLE_SEARCH_CONSOLE_SUCCESS:
      return state
        .set("loading", false)
        .set(
          "google_search_console_website",
          action.payload.google_search_console_website
        )
        .set(
          "google_search_console_status",
          action.payload.google_search_console_status
        )
        .set(
          "google_search_console_list_website",
          action.payload.google_search_console_list_website
        );
    case types.EDIT_GOOGLE_SEARCH_CONSOLE_SUCCESS:
      const status = action.payload.google_search_console_status;
      if (status === false) {
        return state
          .set("loading", false)
          .set("google_search_console_status", status);
      }
      return state.set("loading", false);
    case types.GET_PERFORMANCE:
      return state.set("filter_loading", true);
    case types.GET_PERFORMANCE_SUCCESS:
      if (action.payload.rows) {
        return state
          .set("filter_loading", false)
          .set("rows", action.payload.rows);
      }
      return state.set("filter_loading", false).set("rows", [
        {
          clicks: 0,
          ctr: 0,
          impressions: 0,
          position: 0,
          keys: [],
        },
      ]);
    case types.GET_SITEMAPS:
      return state.set("sitemap_loading", true);
    case types.GET_SITEMAPS_SUCCESS:
      return state
        .set("sitemap_loading", false)
        .set(
          "google_search_console_website",
          action.payload.google_search_console_website
        )
        .set("sitemap", action.payload.sitemap)
        .set("page", action.payload.page);
    case types.DELETE_SITEMAP:
      return state.set("sitemap_delete_loading", true);
    case types.DELETE_SITEMAP_SUCCESS:
      const sitemapNew = state
        .get("sitemap")
        .filter(sitemapItem => sitemapItem.path !== action.payload.path);
      return state
        .set("sitemap_delete_loading", false)
        .set("sitemap", sitemapNew);

    default:
      return state;
  }
};
