import { callApi } from "./service";

const dashboardApi = process.env.REACT_APP_DASHBOARD_API;

export function* getGoogleSearchConsole() {
  const payload = {
    method: "GET",
    url: `${dashboardApi}/seo/google-search-console`,
    params: {},
  };

  return yield callApi(payload);
}

export function* editGoogleSearchConsole(data) {
  const payload = {
    method: "PATCH",
    url: `${dashboardApi}/seo/google-search-console`,
    params: data.payload,
  };
  return yield callApi(payload);
}

export function* getPerformance(data) {
  const payload = {
    method: "GET",
    url: `${dashboardApi}/seo/search-analytics-report`,
    params: data.payload,
  };

  return yield callApi(payload);
}

export function* getSitemaps(data) {
  const payload = {
    method: "GET",
    url: `${dashboardApi}/seo/google-sitemap-list`,
    params: data.payload,
  };

  return yield callApi(payload);
}

export function* submitSitemap(data) {
  const payload = {
    method: "POST",
    url: `${dashboardApi}/seo/google-sitemap`,
    params: data.payload,
  };
  return yield callApi(payload);
}

export function* deleteSitemap(data) {
  const payload = {
    method: "DELETE",
    url: `${dashboardApi}/seo/google-sitemap`,
    params: data.payload,
  };

  return yield callApi(payload);
}
