import { fromJS } from "immutable";

// types
import * as types from "../types/site-verifications";

const initialState = fromJS({
  loading: false,
  site_verification_status: false,
  site_verification_google: "",
  site_verification_bing: "",
  site_verification_pinterest: "",
  site_verification_yandex: "",
  site_verification_baidu: "",
  site_verification_norton: "",
  site_verification_alexa: "",
});

export const siteVerificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_SETTINGS:
      return state.set(action.payload.field, action.payload.value);
    case types.GET_SITE_VERIFICATIONS:
      return state.set("loading", true);
    case types.GET_SITE_VERIFICATIONS_SUCCESS:
      return state
        .set("loading", false)
        .set(
          "site_verification_status",
          action.payload.site_verification_status
        )
        .set(
          "site_verification_google",
          action.payload.site_verification_google
        )
        .set("site_verification_bing", action.payload.site_verification_bing)
        .set(
          "site_verification_pinterest",
          action.payload.site_verification_pinterest
        )
        .set(
          "site_verification_yandex",
          action.payload.site_verification_yandex
        )
        .set("site_verification_baidu", action.payload.site_verification_baidu)
        .set(
          "site_verification_norton",
          action.payload.site_verification_norton
        )
        .set("site_verification_alexa", action.payload.site_verification_alexa);

    default:
      return state;
  }
};
