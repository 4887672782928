import Cookies from "js-cookie";

// helpers
import { getParamsUrlString, getVal } from "@/helpers";

import { callApi } from "./service";

const shop = getParamsUrlString(Cookies.get("autoketing_session"), "shop");
const shopUrl = getVal("shop") || shop;
const commonServiceApi = process.env.REACT_APP_API_COMMON_SERVICE;
const dashboardApi = process.env.REACT_APP_DASHBOARD_API;

export function* changeStatus(data) {
  const { field, value } = data.payload;
  const payload = {
    method: "PATCH",
    url: `${dashboardApi}/seo/on-off-app`,
    params: {
      shop_domain: shopUrl,
      [field]: value,
    },
  };
  return yield callApi(payload);
}

export function* getStatus() {
  const payload = {
    method: "GET",
    url: `${dashboardApi}/seo/get-settings`,
    params: {
      shop_domain: shopUrl,
    },
  };
  return yield callApi(payload);
}

export function* getShopInfo(shop) {
  const payload = {
    method: "GET",
    url: `${commonServiceApi}/ak/get-shop-info`,
    params: {
      shop_domain: shop,
    },
  };
  return yield callApi(payload);
}

export function* getListNotification(shop) {
  const payload = {
    method: "GET",
    params: {
      app_code: "seo",
      shop_domain: shop,
    },
    url: `${commonServiceApi}/ak/get-notification`,
  };
  return yield callApi(payload);
}

export function* getAppSettings() {
  const payload = {
    method: "GET",
    url: `${dashboardApi}/seo/get-settings`,
    params: {},
  };
  return yield callApi(payload);
}
