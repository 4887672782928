import { callApi, callApiJSON } from "./service";

const dashboardApi = process.env.REACT_APP_DASHBOARD_API;

export function* getImageOptimize() {
  const payload = {
    method: "GET",
    url: `${dashboardApi}/seo/image-optimization`,
    params: {},
  };

  return yield callApi(payload);
}

export function* getOptimizeByShop() {
  const payload = {
    method: "GET",
    url: `${dashboardApi}/seo/get-optimize-by-shop`,
    params: {},
  };

  return yield callApi(payload);
}

export function* nextRunOptimizeByShop() {
  const payload = {
    method: "GET",
    url: `${dashboardApi}/seo/next-run-optimize-by-shop`,
    params: {},
  };

  return yield callApi(payload);
}

export function* pauseRunOptimizeByShop() {
  const payload = {
    method: "GET",
    url: `${dashboardApi}/seo/pause-run-optimize-by-shop`,
    params: {},
  };

  return yield callApi(payload);
}

export function* nextRevertOptimizeByShop() {
  const payload = {
    method: "GET",
    url: `${dashboardApi}/seo/next-revert-optimize-by-shop`,
    params: {},
  };

  return yield callApi(payload);
}

export function* pauseRevertOptimizeByShop() {
  const payload = {
    method: "GET",
    url: `${dashboardApi}/seo/pause-revert-optimize-by-shop`,
    params: {},
  };

  return yield callApi(payload);
}

export function* editImageOptimize(data) {
  const payload = {
    method: "PATCH",
    url: `${dashboardApi}/seo/image-optimization`,
    params: data.payload,
  };
  return yield callApi(payload);
}

export function* setOptimizeByShop(data) {
  const payload = {
    method: "POST",
    url: `${dashboardApi}/seo/set-optimize-by-shop`,
    params: JSON.stringify(data.payload),
  };
  return yield callApiJSON(payload);
}

export function* manualRunOneImageProductByShop(data) {
  let subUrl = "manual-run-one-image-product-by-shop";
  const mediaType = data.payload.mediaType;
  if (mediaType === 2) {
    subUrl = "manual-run-one-image-collection-by-shop";
  } else if (mediaType === 3) {
    subUrl = "manual-run-one-image-article-by-shop";
  }
  const payload = {
    method: "GET",
    url: `${dashboardApi}/seo/${subUrl}`,
    params: {
      _id: data.payload._id,
      alt: data.changeAlt ? 5 : "",
      filename: data.changeFilename ? 5 : "",
    },
  };
  return yield callApiJSON(payload);
}

export function* manualRevertOneImageProductByShop(data) {
  let subUrl = "manual-revert-one-image-product-by-shop";
  const mediaType = data.payload.mediaType;
  if (mediaType === 2) {
    subUrl = "manual-revert-one-image-collection-by-shop";
  } else if (mediaType === 3) {
    subUrl = "manual-revert-one-image-article-by-shop";
  }
  const payload = {
    method: "GET",
    url: `${dashboardApi}/seo/${subUrl}`,
    params: {
      _id: data.payload._id,
      alt: data.changeAlt ? 10 : "",
      filename: data.changeFilename ? 10 : "",
    },
  };
  return yield callApiJSON(payload);
}

export function* getHistoryImageOptimize(data) {
  const payload = {
    method: "GET",
    url: `${dashboardApi}/seo/image-list-optimization`,
    params: data.payload,
  };
  return yield callApi(payload);
}

export function* getDataImageByShop(data) {
  const payload = {
    method: "GET",
    url: `${dashboardApi}/seo/get-data-image-by-shop`,
    params: { limit: 8, ...data.payload },
  };
  return yield callApi(payload);
}

export function* revertImageOptimize(data) {
  const payload = {
    method: "POST",
    url: `${dashboardApi}/seo/revert-image-by-id`,
    params: data.payload,
  };
  return yield callApi(payload);
}
